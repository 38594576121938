import React, { useState, useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext'
import Input from '../../components/common/Input';
import LogoMobile from '../../assets/sidebar/Logo'
import LogoCreelColor from '../../assets/home/LogoCreelColor';
import { useLocation } from "wouter";
import { postData } from '../../utils/form'
import ModalForm from '../../components/common/ModalForm';


const FormContainer = ({items}) => {
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [correo, setCorreo] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [telefono, setTelefono] = useState('');
  const [pais, setPais] = useState('');
  const [comentarios, setComentarios] = useState('');
  const [statusMsg, setStatusMsg] = useState('')


  const [isNombreInvalid, setNombreInvalid] = useState(false);
  const [isApellidoInvalid, setApellidoInvalid] = useState(false);
  const [isCorreoInvalid, setCorreoInvalid] = useState(false);
  const [isTelefonoInvalid, setTelefonoInvalid] = useState(false);
  const [termns, setTermns] = useState(false)

  const [openModalForm, setOpenModalForm] = useState(false);
  const { language } = useContext(LanguageContext);

  const [location, setLocation] = useLocation();

  const disabledButton = nombre && apellido && correo && telefono && comentarios && termns ? true : false

  const navigate = (url) => {
    setLocation(url);
  };

  const handleSubmit = () => {
    if (!nombre || !apellido || !correo || !telefono) {
      setNombreInvalid(!nombre);
      setApellidoInvalid(!apellido);
      setCorreoInvalid(!correo);
      setTelefonoInvalid(!telefono)
      return; 
    }
    const body = {
      name: `${nombre} ${apellido}`,
      email: correo,
      telephone: telefono,
      country: pais,
      company: empresa,
      comments: comentarios
    }
    postData(body)
    .then((response) => {
        if (response.status === 200) {
            setStatusMsg(response.data.msg);
            setNombre('');
            setApellido('');
            setCorreo('');
            setEmpresa('');
            setTelefono('');
            setPais('');
            setComentarios('');
            setTermns(false);
        } else {
            setStatusMsg(response.data.message);
        }
        setOpenModalForm(true);
    });
}

  return (
    <div className='w-full h-full flex flex-col sm:flex-row pt-[30px] justify-between 2xl:justify-start'>
        <div className='sm:w-2/6'>
            <div className='block sm:hidden w-[74px] h-[74px]'>
                <LogoMobile/>
            </div>
            <div className='pt-[45px] hidden sm:block'>
                <p style={{fontFamily: 'Poppins Medium'}} className='text-primary sm:text-[21px] text-[30px] pb-[20px] mt-[-20px]'>{items.title}</p>
            </div>
            <div className='w-full h-full hidden sm:block'>
                <div className='w-full max-w-[426px] px-[20px]'>
                    <LogoCreelColor width="80%"/>
                </div>
            </div>
        </div>
        <div className='p-0 max-w-[738px]'>
            <div className='flex flex-col sm:justify-start justify-center sm:items-baseline items-center'>
                <p style={{fontFamily: 'Merriweather Italic'}} className='text-[#D1C5B2] sm:text-[28px] text-[18px] max-w-[900px] text-left mb-[15px] mt-[20px] px-[20px] sm:px-0 sm:w-auto w-full'>{items.subtitle}</p>
                <p style={{fontFamily: 'Poppins'}} className='sm:w-auto w-full px-[20px] sm:px-0 sm:text-[14px] text-[9px]'>*{items.description}</p>
            </div>
                <form onSubmit={handleSubmit} className="flex flex-col sm:items-start mb-[80px] px-[20px] sm:px-0 items-center w-full">
                    <div className='sm:max-w-[900px] w-full flex sm:flex-wrap sm:flex-row flex-col items-center justify-between'>
                        <div className='w-[280px] sm:w-[358px] my-[20px]'>
                            <Input
                            type="text"
                            placeholder={`*${items.fields.name}`}
                            value={nombre}
                            onChange={(e) => setNombre(e.target.value)}
                            required
                            isInvalid={isNombreInvalid}
                        />
                    </div>
                    <div className='w-[280px] sm:w-[358px] my-[20px]'>
                        <Input
                            type="text"
                            placeholder={`*${items.fields.lastname}`}
                            value={apellido}
                            onChange={(e) => setApellido(e.target.value)}
                            required
                            isInvalid={isApellidoInvalid}
                        />
                    </div>
                    </div>
                    <div className='flex flex-col sm:items-start items-center justify-center w-full'>
                    <div className='my-[20px] w-full'>
                        <Input
                            type="email"
                            placeholder={`*${items.fields.email}`}
                            value={correo}
                            onChange={(e) => setCorreo(e.target.value)}
                            required
                            isInvalid={isCorreoInvalid}
                            full
                        />
                    </div>
                    <div className='w-full my-[20px]'>
                        <Input
                            type="tel"
                            placeholder={`*${items.fields.phone}`}
                            value={telefono}
                            onChange={(e) => setTelefono(e.target.value)}
                            required
                            isInvalid={isTelefonoInvalid}
                            full
                        />
                    </div>
                    <div className='w-full my-[20px]'>
                    <Input
                            type="text"
                            placeholder={items.fields.company}
                            value={empresa}
                            onChange={(e) => setEmpresa(e.target.value)}
                            full
                        />
                    </div>
                    <div className='w-full my-[20px]'>
                        <Input
                            type="text"
                            placeholder={items.fields.country}
                            value={pais}
                            onChange={(e) => setPais(e.target.value)}
                            full
                        />
                    </div>
                        <textarea
                            placeholder={items.fields.comments}
                            value={comentarios}
                            onChange={(e) => setComentarios(e.target.value)}
                            className="sm:w-[738px] w-[280px] h-[140px] sm:my-[15px] sm:mr-0 mr-[15px] border border-primary rounded px-4 py-2 text-primary focus:outline-none focus:border-primary placeholder-primary::placeholder">
                            </textarea>
                            <div className="flex w-full sm:justify-start justify-center">
                                <input onChange={() => setTermns(!termns)} checked={termns} className="mr-2 w-[22px] h-[22px]" type="checkbox"/>
                                <label onClick={() => {navigate('/privacy-notice')}} className="md:w-2/3 flex underline cursor-pointer">
                                <span className="text-sm text-[#707070]">
                                    {items.termns}
                                </span>
                                </label>
                            </div>
                        <button disabled={!disabledButton} onClick={handleSubmit} type="button" className="w-[211px] h-[51px] bg-primary text-[#FFF] rounded px-4 py-2 mt-4 disabled:opacity-75">
                        {items.button}
                        </button>
                        <p style={{fontFamily: 'Poppins Medium'}} className='text-[16px] text-primary mt-[20px]'> {statusMsg} </p>
                        <div className='flex flex-col'>
                            <p className='text-[#707070]'>{language== 'es' ? 'Recursos Humanos': 'Human Resources'}</p>
                            <p className='text-[#707070]'><a href="mailto:rh@creelabogados.com">rh@creelabogados.com</a></p>
                            <p className='text-[#707070]'>+52 (55) 1167-3000</p>
                        </div>
                    </div>
            </form>
        </div>
        {openModalForm && <ModalForm close={setOpenModalForm} />}
    </div>
  );
};

export default FormContainer;
