import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import Layout from '../../Layout/MainLayout';
import Footer from '../../components/common/Footer'
import bg from '../../assets/historia/NH-Header_escritorio.jpg';
import esFooter from '../../translations/footer/es.json'
import enFooter from '../../translations/footer/es.json'
import esLang from '../../translations/historia/es';
import enLang from '../../translations/historia/en';


const NuestraHistoria = () => {
    const { language, changeLanguage } = useContext(LanguageContext);
    const translation = language === 'es' ? esLang : enLang;
    const translationFooter = language === 'es' ? esFooter : enFooter;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout noFooter noScrollNavbar>
            <style>{`
            .timelineFixed {
                display: none;
                @media (min-width: 1210px) {
                    display: flex
                }
            }
            `}</style>
            <div className='w-screen'>
                <div
                    id="Nuestra historia"
                    style={{ backgroundImage: `url(${bg})`, backgroundSize: 'cover' }}
                    className='w-full min-h-[590px] lg:py-[90px] lg:px-[35px] relative z-20 bg-center'
                >
                    <div className='bg-[#000000] bg-opacity-[.69] w-full h-screen lg:h-full lg:min-h-[406px] flex flex-col justify-center items-center lg:px-[90px] lg:p-0 p-[45px]'>
                        <h1 style={{ fontFamily: 'Merriweather Italic' }} className='text-[#D1C5B2] text-[30px] lg:text-[40px] text-center'>
                            {translation.title}
                        </h1>
                        <p style={{ fontFamily: 'Poppins' }} className='max-w-[1200px] text-[#fff] lg:text-[26px] text-[18px] mt-[15px] text-center'>{translation.subtitle}</p>
                    </div>
                </div>
                <div className='flex w-full'>
                    <div className='timeline flex flex-col bg-[#E4E4E4] relative z-10 w-full'>
                        <div className='timelineFixed flex-col fixed ml-[10px] top-[240px]'>
                            <p style={{ fontFamily: 'Poppins Bold' }} className='text-[11px] text-[#7F7F7F] text-center'> {translation.timeline.name} </p>
                            {
                                translation.timeline.items.map(item =>
                                    <div className='border-solid border-b-2 border-[#D1C5B2]'>
                                        <a href={`#${item === "Today" ? "Hoy" : item}`} className='block cursor-pointer py-[10px] text-[14px] text-[#7F7F7F] text-center hover:text-primary font-Poppins hover:font-Poppins-Bold hover:-translate-y-2'> {item} </a>
                                    </div>
                                )}
                        </div>
                        <div className='events pt-[190px] flex flex-col items-center w-screen'>
                            <div id='1936' className='event flex lg:flex-row flex-col border-solid border-b-4 lg:border-b-0 border-[#D1C5B2]'>
                                <div className='order-2 lg:order-1 lg:w-1/2 px-[30px] py-[60px] lg:max-w-[590px]'>
                                    <div className='flex flex-col items-center'>
                                        {translation.t1.image.map((image) => (
                                            <img src={image} alt='Image 2' className='lg:max-w-[473px] max-w-[273px] h:-[451px] lg:h-[561px]' />
                                        ))}
                                        <p style={{ fontFamily: 'Poppins Light' }} className='text-[16px] text-[#7F7F7F] mt-[30px] text-center max-w-[360px]'>{translation.t1.image_description}</p>
                                    </div>
                                </div>
                                <div className='order-1 lg:order-2 lg:w-1/2 px-[30px] border-solid lg:border-l-4 border-[#D1C5B2] max-w-[590px]'>
                                    <h3 style={{ fontFamily: 'Merriweather Italic Bold' }} className='text-[40px] font-bold mb-2 text-[#000000] pb-[30px]'>
                                        {translation.t1.title}
                                    </h3>
                                    {translation.t1.description.map((p) => (
                                        <p dangerouslySetInnerHTML={{ __html: p }} style={{ fontFamily: 'Poppins Light' }} className='text-[16px] text-[#7F7F7F] pb-[30px] max-w-[526px] leading-[1.6]' />
                                    ))}
                                </div>
                            </div>
                            <div id='1950' className='event flex lg:flex-row flex-col border-solid border-b-4 lg:border-b-0 border-[#D1C5B2]'>
                                <div className='lg:w-1/2 px-[30px] max-w-[590px]'>
                                    <h3 style={{ fontFamily: 'Merriweather Italic Bold' }} className='text-[40px] font-bold mb-2 text-[#000000] pb-[30px] lg:text-right text-left'>
                                        {translation.t2.title}
                                    </h3>
                                    {translation.t2.description.map((p) => (
                                        <p style={{ fontFamily: 'Poppins Light' }} className='text-[16px] text-[#7F7F7F] pb-[30px] leading-[1.6]'>
                                            {p}
                                        </p>
                                    ))}
                                </div>
                                <div className='lg:w-1/2 px-[0px] border-solid lg:border-l-4 border-[#D1C5B2] py-[0px] max-w-[590px]'>
                                    <div className='flex justify-evenly flex-wrap mb-[60px] lg:mb-0'>
                                        {translation.t2.image.map((image) => (
                                            <img src={image} alt='Image 2' className='w-[240px] h-auto' />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div id='1955' className='event flex lg:flex-row flex-col border-b-4 lg:border-b-0 border-[#D1C5B2]'>
                                <div className='order-2 lg:order-1 lg:w-1/2 px-[30px] pt-[30px] max-w-[590px] mb-[50px] lg:mb-0'>
                                    <div className='flex justify-evenly flex-wrap'>
                                        {translation.t3.image.map((image) => (
                                            <img src={image} alt='Image 2' className='w-[240px] h-auto relative' />
                                        ))}
                                    </div>
                                </div>
                                <div className='order-1 lg:order-2 lg:w-1/2 px-[30px] border-solid lg:border-l-4 border-[#D1C5B2] border-b-0 pt-[30px] max-w-[590px]'>
                                    <h3 style={{ fontFamily: 'Merriweather Italic Bold' }} className='text-[40px] font-bold mb-2 text-[#000000] pb-[30px]'>
                                        {translation.t3.title}
                                    </h3>
                                    {translation.t3.description.map((p) => (
                                        <p style={{ fontFamily: 'Poppins Light' }} className='text-[16px] text-[#7F7F7F] pb-[30px] leading-[1.6]'>
                                            {p}
                                        </p>
                                    ))}
                                </div>
                            </div>
                            <div id='1972' className='event flex lg:flex-row flex-col border-solid border-[#D1C5B2] lg:border-b-0 border-b-4 mt-[30px] lg:mt-0'>
                                <div className='lg:w-1/2 px-[30px] max-w-[590px]'>
                                    <h3 style={{ fontFamily: 'Merriweather Italic Bold' }} className='text-[40px] font-bold mb-2 text-[#000000] pb-[30px] text-right'>
                                        {translation.t4.title}
                                    </h3>
                                    {translation.t4.description.map((p) => (
                                        <p dangerouslySetInnerHTML={{ __html: p }} style={{ fontFamily: 'Poppins Light' }} className='text-[16px] text-[#7F7F7F] bg-[#E4E4E4] pb-[30px] leading-[1.6]' />
                                    ))}
                                </div>
                                <div className='lg:w-1/2 px-[30px] border-solid lg:border-l-4 border-[#D1C5B2] py-[40px] max-w-[590px]'>
                                    <div className='flex justify-evenly flex-wrap'>
                                        {translation.t4.image.map((image) => (
                                            <img src={image} alt='Image 2' className='w-[240px] h-auto' />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div id='1977' className='event flex lg:flex-row flex-col border-solid border-[#D1C5B2] lg:border-b-0 border-b-4'>
                                <div className='order-2 lg:order-1 lg:w-1/2 px-[30px] pt-[20px] max-w-[590px] lg:mb-0 mb-[30px]'>
                                    <div className='flex justify-evenly flex-wrap'>
                                        {translation.t5.image.map((image) => (
                                            <img src={image} alt='Image 2' className='w-[240px] h-auto relative' />
                                        ))}
                                    </div>
                                </div>
                                <div className='order-1 lg:order-2 lg:w-1/2 px-[30px] border-solid lg:border-l-4 lg:border-b-0 border-[#D1C5B2] py-0 max-w-[590px]'>
                                    <h3 style={{ fontFamily: 'Merriweather Italic Bold' }} className='text-[40px] font-bold mb-2 text-[#000000] pb-[30px]'>
                                        {translation.t5.title}
                                    </h3>
                                    {translation.t5.description.map((p) => (
                                        <p dangerouslySetInnerHTML={{ __html: p }} style={{ fontFamily: 'Poppins Light' }} className='text-[16px] text-[#7F7F7F] pb-[30px] leading-[1.6]' />
                                    ))}
                                </div>
                            </div>
                            <div id='2000' className='event flex lg:flex-row flex-col border-solid lg:border-b-0 border-b-4 border-[#D1C5B2]'>
                                <div className='lg:w-1/2 px-[15px] pt-[0px] max-w-[590px]'>
                                    <h3 style={{ fontFamily: 'Merriweather Italic Bold' }} className='text-[40px] font-bold mb-2 text-[#000000] pb-[30px] text-right'>
                                        {translation.t6.title}
                                    </h3>
                                    {translation.t6.description.map((p) => (
                                        <p dangerouslySetInnerHTML={{ __html: p }} style={{ fontFamily: 'Poppins Light' }} className='text-[16px] text-[#7F7F7F] bg-[#E4E4E4] pb-[30px] leading-[1.6]' />
                                    ))}
                                </div>
                                <div className='w-1/2 px-[30px] border-solid lg:border-l-4 border-[#D1C5B2] py-[30px] max-w-[590px]'>
                                    <div className='flex justify-between'>
                                        {translation.t6.image.map((image) => (
                                            <img src={image} alt='Image 2' className='w-[239px] pt-0 h-[343px] object-cover' />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div id='Hoy' className='event flex lg:flex-row flex-col border-solid border-b-4 lg:border-b-0 border-[#D1C5B2]'>
                                <div className='order-2 lg:order-1 lg:w-1/2 px-[30px] pt-[20px] max-w-[590px] pb-[100px]'>
                                    <div className='flex flex-col justify-center'>
                                        <div className='flex-col justify-center'>
                                            <img src={translation.t7.image[0]} alt='Image 2' className='w-[528px] h-[523px] mb-[40px] object-cover' />
                                            <p style={{ fontFamily: 'Poppins Light' }} className='text-[16px] text-[#7F7F7F] mt-[30px] text-center pb-[30px] bg-[#E4E4E4]'>{translation.t7.image_description}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='order-1 lg:order-2 lg:w-1/2 px-[30px] border-solid lg:border-l-4 border-[#D1C5B2] py-[20px] max-w-[590px]'>
                                    <h3 style={{ fontFamily: 'Merriweather Italic Bold' }} className='text-[40px] font-bold mb-2 text-[#000000] pb-[30px]'>
                                        {translation.t7.title}
                                    </h3>
                                    {translation.t7.description.map((p) => (
                                        <p dangerouslySetInnerHTML={{ __html: p }} style={{ fontFamily: 'Poppins Light' }} className='text-[16px] text-[#7F7F7F] pb-[30px] leading-[1.6]  bg-[#E4E4E4]' />
                                    ))}
                                </div>
                            </div>
                            <div className='flex flex-col items-center justify-center mb-[90px] max-w-[1167px]'>
                                <h3 style={{ fontFamily: 'Merriweather Italic Bold' }} className='text-[40px] font-bold mb-2 text-[#000000] pb-[30px]'>
                                    {translation.t8.title}
                                </h3>
                                {translation.t8.description.map((p) => (
                                    <p dangerouslySetInnerHTML={{ __html: p }} style={{ fontFamily: 'Poppins Light' }} className='text-[16px] text-[center] text-[#7F7F7F] pb-[30px] leading-[1.6] max-w-[1067px] bg-[#E4E4E4]' />
                                ))}
                                <div className='flex justify-center'>
                                    {translation.t8.image.map((image) => (
                                        <img src={image} alt='Image 2' className='w-full h-auto' />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='relative z-20'>
                    <Footer items={translationFooter} />
                </div>
            </div>
        </Layout >
    );
};

export default NuestraHistoria;
