import React, { useState, useEffect } from 'react';
import { useLocation } from "wouter";

import ArrowLeft from '../../assets/common/ArrowLeft';
import ArrowRight from '../../assets/common/ArrowRight';
import Searchbar from './Searchbar';

function Tabs({ tabs, contents, v2, center, searchbar, query, setQuery, width }) {
  const [location, setLocation] = useLocation();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tab = parseInt(params.get("page"), 10);
    if (!isNaN(tab)) {
      setActiveTab(tab);
    }
  }, []);

  const handleTabClick = (index) => {
    setActiveTab(index);
    setLocation(`${window.location.pathname}?page=${index}`);
  };

  const handleNextTab = () => {
    setActiveTab((prevTab) => (prevTab === tabs.length - 1 ? 0 : prevTab + 1));
  };

  const handlePrevTab = () => {
    setActiveTab((prevTab) => (prevTab === 0 ? tabs.length - 1 : prevTab - 1));
  };

  return (
    <div className="sm:w-auto w-full">
      <div className='flex items-baseline'>
        <div className={`${center ? 'flex justify-center' : 'flex sm:justify-normal justify-center w-full'}`} style={{borderBottom: v2 ? '1px solid #D1C5B2' : ''}}>
          {activeTab > 0 && (
            <button className="px-4 py-2 sm:hidden block absolute mr-[200px]" onClick={handlePrevTab}>
              <div className='w-[16px]'>
                <ArrowLeft fill='#D1C5B2'/>
              </div>
            </button>
          )}
            {tabs.map((tab, index) => (
              <button
                style={{ fontFamily: v2 ? 'Poppins Light' : 'Poppins', width: width ? width+'px' : '' }}
                key={index}
                className={`px-4 py-2 mr-2 ${
                  !v2 && activeTab === index ? 'border-b-4 border-[#D1C5B2] text-[#666666]' : 'hidden sm:block'
                } ${v2 && activeTab === index ? 'bg-[#AA9775] text-[#fff] font-bold rounded-t-lg':'' } ${v2 && activeTab !== index ? 'text-primary font-lighter':''}`}
                onClick={() => handleTabClick(index)}
              >
                {tab.value}
              </button>
            ))}
          {activeTab < tabs.length - 1 && (
            <button className="text-[#D1C5B2] px-4 py-2 font-medium sm:hidden block absolute ml-[200px]" onClick={handleNextTab}>
            <div className='w-[16px]'>
                <ArrowRight fill='#D1C5B2'/>
              </div>
            </button>
          )}
        </div>
         { searchbar && <div className='ml-auto hidden sm:block'>
          <Searchbar query={query} setQuery={setQuery}/>
        </div>}
      </div>
      <div className={`p-0 sm:py-[40px] ${center ? 'bg-[#Fff]' : 'bg-[#fff]'}`}>{contents[activeTab]}</div>
    </div>
  );
}

export default Tabs;
