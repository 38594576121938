import axios from 'axios';

export const getArticles = async () => {
    try {
      const response =  await axios.get('https://apicreel.digitalcoaster.mx/api/listado-articulos');
      return response.data
    } catch (error) {
      console.error(error);
    }
  };
  export const getArticle = async (slug) => {
    try {
      const response =  await axios.get(`https://apicreel.digitalcoaster.mx/api/articulo/${slug}`);
      return response.data
    } catch (error) {
      console.error(error);
    }
  };
