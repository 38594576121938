const en = {
    title: 'Keeping in touch and getting to know you is important to us',
    subtitle: 'At Creel/abogados we listen to you',
    p: 'Creel/abogados',
    address: [
        {
            items: ["Paseo de los Tamarindos 400 B, Piso 29", "Bosques de las Lomas", "05120 Ciudad de México"],
            phone: "+52 (55) 1167-3000"
        },
        {
            items: ["Magnia Corporate Center", "Calle 15 No. 503, por 18 y 22, PH 06", "Fracc. Altabrisa", "97130 Mérida, Yucatán"],
            phone: "+52 (99) 9518-2745"
        }
    ],
    form: {
        text: "*Required fields",
        terms: "I accept the terms of the Privacy Notice",
        button: "SEND FORM",
        name: "Name",
        lastname: "Lastname",
        email: "*Email",
        phone: "*Telephone",
        country: "Country",
        company: "Business",
        comments: "*Comments",
    }
}
export default en