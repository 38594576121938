import React, { useState, useContext, useEffect } from 'react';
import { LanguageContext } from '../../context/LanguageContext'

import { getArticles } from '../../utils/articles';

import MainLayout from '../../Layout/MainLayout'
import esLang from '../../translations/recursos/es.js'
import enLang from '../../translations/recursos/en.js'
import HeroContainer from './HeroContainer'
import HighLightContainer from './HighlightContainer';
import ArticlesContainer from './ArticlesContainer';

function Recursos(params) {
    const [articles, setArticles] = useState([])
    const [legalNotes, setLegalNotes] = useState([])

    const { language } = useContext(LanguageContext);
    const translation = language === 'es' ? esLang : enLang

    useEffect(() => {
        window.scrollTo(0, 0);
        getArticles()
            .then((res) => { 

                if(language == 'es') {
                    const notas = res.legalnotes.filter((notes) => notes.idioma == 1);
                    const articulos = res.articulos.filter((notes) => notes.idioma == 1)
                    setLegalNotes(sortByDate(notas))
                    setArticles(sortByDate(articulos))
                }
                if(language == 'en'){
                    const notas = res.legalnotes.filter((notes) => notes.idioma == 2);
                    const articulos = res.articulos.filter((notes) => notes.idioma == 2)
                    setLegalNotes(sortByDate(notas))
                    setArticles(sortByDate(articulos))
                }
            })
            .catch(error => { console.log(error) })
    }, []);

    const sortByDate = (data) => {
      const sorting =  data.sort((a, b) => {
            const dateA = normalizeDate(a.date);
            const dateB = normalizeDate(b.date);
        
            // Si alguna fecha es inválida, tratarla como más antigua
            if (!dateA) return 1;
            if (!dateB) return -1;
        
            // Ordenar de más reciente a más antigua
            return dateB - dateA;
        });
        return sorting
    }

    function normalizeDate(dateStr) {
        // Si la fecha tiene el formato dd/mm/yyyy
        if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateStr)) {
            const [day, month, year] = dateStr.split('/');
            return new Date(year, month - 1, day);
        }
    
        // Si la fecha tiene el formato mm/yyyy
        if (/^\d{2}\/\d{4}$/.test(dateStr)) {
            const [month, year] = dateStr.split('/');
            return new Date(year, month - 1, 1);
        }
    
        // Si la fecha tiene solo el año
        if (/^\d{4}$/.test(dateStr)) {
            return new Date(dateStr, 0, 1); // Mes 0 es enero
        }
    
        // En caso de formato desconocido, devolver null
        return null;
    }

    const articlesEsp = {title: language == 'es' ? "Artículos" : 'Articles', more: language == 'es' ? "Ver todo" : 'See more', items: articles }
    const legalNotesEsp = {title: language == 'es' ? "Notas Jurídicas" : 'Legal Notes', more:language == 'es' ?  "Ver todo" : 'See more', items: legalNotes }

    return (
        <MainLayout>
            <HeroContainer items={translation} />
            {/* <HighLightContainer items={translation["highlight"]} /> */}
            <div className='pb-[120px]'>
                <ArticlesContainer items={articlesEsp} />
                <ArticlesContainer items={legalNotesEsp} />
            </div>
        </MainLayout>
    )

}
export default Recursos