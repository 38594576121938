import React, { useContext } from 'react'
import Mail from '../../assets/equipo/Mail';
import Phone from '../../assets/equipo/Phone';

import { LanguageContext } from '../../context/LanguageContext'


function Modal({items, closeModal}){
    
    const { language } = useContext(LanguageContext);
    
    return (
        <div className='w-[840px] h-[372px] p-[20px] flex flex-col bg-primary'>
            <div className='text-[#fff] flex justify-end pr-[20px]'><p onClick={() => closeModal(false)} className='text-[30px] cursor-pointer w-fit'>X</p></div>
            <div className='flex w-full h-full justify-between items-center'>
                <div className='w-full flex flex-col justify-between items-center pb-[20px]'>
                    <h1 style={{fontFamily: 'Merriweather Bold'}} className='text-[#fff] text-[48px]'>{items.name}</h1>
                    <p style={{fontFamily: 'Merriweather Italic Bold'}} className='text-[16px] text-[#D1C5B2]'>{items.jobTitle}</p>
                    <p style={{fontFamily: 'Poppins'}} className='text-[20px] text-[#D1C5B2] mb-[15px] mt-[20px]'>{items.contact}</p>
                    <div className='text-[#fff] flex'>
                        <Mail fill="#fff"  width="19" height="19"/>
                        <p style={{fontFamily: 'Poppins Light'}} className='text-[#fff ml-[10px]'>{items.phone}</p>
                    </div>
                    <div className='flex'>
                        <Phone fill="#fff" width="19" height="19" />
                        <p style={{fontFamily: 'Poppins Light'}} className='text-[#fff] ml-[10px]'>{items.email}</p>
                    </div>
                    <a download href={items.vcard} style={{fontFamily: 'Merriweather Italic'}} className='bg-[#6F2A43] text-[#D1C5B2] w-[203px] h-[50px] flex justify-center items-center cursor-pointer my-[20px]'> {language === 'es' ? 'Descargar vCard' : 'Download vCard'} </a>
                </div>

            </div>
        </div>
    )
}

export default Modal