const es = {
    title: ["Consult and download our legal notes/articles to learn more", "about updates on legal issues in Mexico."],
    image: require('../../assets/recursos/Nota1.jpg'),
    highlight:{
        title: "Lorem Ipsum",
        description: {
            title: "Nullam venenatis:",
            name: "Proin viverra",
            extra: "Aliquam iaculis erat enim, vel consequat ipsum commodo non",
            button: "Cras molestie"
        }
    },
    articles: {
        title: "Articles",
        more: "Read more",
        items: [
            {
                title: "Latin-America Fintech Regulation – Third Edition 2022",
                description: "We are pleased to present the third edition of “Fintech Regulation in Latin America”.",
                more: "Read more",
                date: "2022",
                link: require('../../assets/recursos/articulos/Latin-America Fintech Regulation - Third Edition 2022.pdf')
            },
            {
                title: "ICLG Corporate Governance Laws and Regulations 2022",
                description: "This chapter will focus on the sociedad anónima bursátil (“SAB”), the exclusive corporate form for Mexican listed companies and a modality of the sociedad anónima (“SA”)...",
                more: "Read more",
                date: "01/06/2022",
                link: require('../../assets/recursos/articulos/ICLG Corporate Governance Laws and Regulations 2022.pdf')
            }
        ]
    },
    notes: {
        title: "Legal Notes",
        more: "Read more",
        items: [
            {
                title: "Amendments to Outsourcing Regulation",
                description: "On April 23, 2021, the Decree (“Decree”) that amends, adds and repeals several provisions of the following federal statutes relating to Outsourcing...",
                more: "Read more",
                date: "26/04/2021",
                link: require('../../assets/recursos/notas/Creel Abogados - Legal Note - Amendments to Outsourcing Regulation April 2021.pdf')
            },
            {
                title: "Amendment to the Labor Law",
                description: "On December 14, 2021, the Senate approved the Bill to amend Articles 76 and 78 of the Federal Labor Law (“FLL”) related to the minimum...",
                more: "Read more",
                date: "2023",
                link: require('../../assets/recursos/notas/Creel Abogados - Legal Note - Amendment to the Labor Law - Vacation Period December 2022.pdf')
            },
            {
                title: "Controlling Beneficiary",
                description: "On January 1, 2022, the Federal Tax Code (Código Fiscal de la Federación; “Tax Code”) included the Articles 32-B Ter, 32-B Quater and...",
                more: "Read more",
                date: "2023",
                link: require('../../assets/recursos/notas/Creel Abogados - Legal Note - Controlling Beneficiary June 2023.pdf')
            },
            {
                title: "Bill to Amend the LGSM",
                description: "On April 27, 2023, the Joint Committees on Economy and Legislative Studies of the Senate (Comisiones Unidas de Economía y de Estudios Legislativos...",
                more: "Read more",
                date: "27/04/2023",
                link: require('../../assets/recursos/notas/Creel Abogados - News Alert - Bill to Amend the LGSM - Telematics Meetings April 2023.pdf')
            }
        ]
    }
}

export default es;