import { LanguageProvider } from './context/LanguageContext';
import Home from './containers/Home'
import NuestroDespacho from './containers/NuestroDespacho'
import AreasPractica from './containers/AreasPractica'
import NuestroEquipo from './containers/NuestroEquipo'
import Biography from './containers/Biografia';
import NuestraHistoria from './containers/NuestraHistoria';
import Recursos from './containers/Recursos';
import Contacto from './containers/Contacto'
import TYC from './containers/TYC';
import Detail from './containers/Recursos/Detail'

import { Route, Switch } from "wouter";

function App() {

  
  return (
    <LanguageProvider>
        <Switch>
          <Route path="/" component={Home}/>
          <Route path="/our-firm" component={NuestroDespacho} />
          <Route path="/practice-areas" component={AreasPractica} />
          <Route path="/our-team" component={NuestroEquipo} />
          <Route path="/partners/:id" component={Biography}/>
          <Route path="/advisers/:id" component={Biography}/>
          <Route path="/associates/:id" component={Biography}/>
          <Route path="/our-history" component={NuestraHistoria} />
          <Route path="/resources" component={Recursos} />
          <Route path='/contact' component={Contacto} />
          <Route path='/privacy-notice' component={TYC}/>
          <Route path="/articles/:slug" component={Detail} />
        </Switch>
    </LanguageProvider>
  );
}

export default App;
