const es = {
    title: 'Aviso de privacidad',
    p: [
        'Creel Abogados, S.C. con domicilio en Paseo de los Tamarindos 400 B, Piso 29, Col. Bosques de las Lomas, 05120 Ciudad de México, es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección',
        'Su información personal será utilizada para prestar los servicios profesionales de representación legal que ha contratado, realizar los trámites necesarios para los servicios de representación legal e informar sobre la situación y estado de los asuntos que sean llevados en su representación. Para las finalidades antes mencionadas, requerimos obtener los siguientes datos personales: nombre, domicilio, número(s) telefónico(s) y correo electrónico. La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo para que le neguemos los servicios que solicita o contrata con nosotros.',
        `Usted tiene derecho de acceder, rectificar y cancelar sus datos personales, así como de oponerse al tratamiento de los mismos o revocar el consentimiento que para tal fin nos haya otorgado, a través de los procedimientos implementados. Para conocer los procedimientos, requisitos y plazos para acceder, rectificar y cancelar sus datos, puede contactar a: Antonio Peña, tel: +52 (55) 1167-3055 y correo electrónico: <a style="font-family: Poppins Medium" href='mailto:antonio.pena@creelabogados.com' class='text-[15px] text-[#415C87]'>antonio.pena@creelabogados.com </a>`,
        'Asimismo, le informamos que sus datos personales pueden ser transferidos y tratados dentro y fuera del país, por personas distintas a esta Firma. En ese sentido, su información puede ser compartida con otras sociedades o personas que presten servicios profesionales, para complementar los servicios legales que se han contratado y para contactar a peritos relacionados con los asuntos en los que usted sea representado. Si usted no manifiesta su oposición para que sus datos personales sean transferidos, se entenderá que ha otorgado su consentimiento para ello.',
        `Nos reservamos el derecho a efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad. Estas modificaciones estarán disponibles en nuestra página de Internet: <a style="font-family: Poppins Bold" target="_blank" href='https://www.creelabogados.com' class='text-[15px] text-[#415C87] underline'>www.creelabogados.com</a>`
    ],
    actualization: `Fecha de última actualización: Agosto 2024`,
    derechos: `<span style="font-family: Poppins Medium">Derechos de Autor </span> – Todos los derechos reservados © Creel Abogados, S.C.`,
}
export default es;