import React from 'react'
import img1 from '../../assets/despacho/probono/ND-Probono_escritorio.jpg'
import logo1 from '../../assets/despacho/probono/logo_Amsa.png'
import logo2 from '../../assets/despacho/probono/logo_Exeb.png'
import logo3 from '../../assets/despacho/probono/logo_Grupo16.jpg'
import logo4 from '../../assets/despacho/probono/logo_osl.png'
import logo5 from '../../assets/despacho/probono/logo_Findesac.png'
import logo6 from '../../assets/despacho/probono/unnamed.png'


function ProBonoContainer({items}){
    return (
        <div id='pro-bono' className='w-screen flex flex-col bg-[#F8F8F8]'>
            <div className='flex flex-col sm:flex-row w-full h-full'>
                <div className='pt-[90px] sm:pl-[90px] px-[30px] sm:px-0 h-auto sm:w-3/6 w-screen'>
                    <div className='flex flex-col h-full justify-center border-l-[#D1C5B2] border-solid sm:border-l-8 border-l-4 pl-[30px]'>
                        <p style={{fontFamily: 'Merriweather Italic Bold'}} className='text-primary sm:text-[40px] text-[30px]'>{items.title}</p>
                        <p style={{fontFamily: 'Merriweather Italic'}} className='text-[#D1C5B2] sm:text-[30px] text-[20px]'>{items.subtitle}</p>
                    </div>
                </div>
                <div className='sm:pl-[40px] sm:pt-[90px] h-full sm:w-3/6 w-screen pt-[30px]'>
                    <div className='w-full'>
                        <img className='object-cover w-full' src={img1}></img>
                    </div>
                </div>
            </div>
            <div className='sm:p-[90px] p-[60px] pt-[40px]'>
                <p style={{fontFamily: 'Poppins Light'}} className='text-[#7F7F7F] sm:text-[20px] text-[16px] pt-[30px]'>{items.p1}</p>
                <p style={{fontFamily: 'Poppins Light'}} className='text-[#7F7F7F] sm:text-[20px] text-[16px] pt-[30px]'>{items.p2}</p>
            </div>
            <div className='flex flex-wrap w-full sm:items-center sm:justify-between justify-evenly sm:h-[203px] sm:px-[90px] sm:py-[40px] pb-[60px] sm:pb-[30px] bg-[#FFF]'>
                <div className='h-[53px] w-[163px] my-[20px] sm:my-0'><img src={logo1}></img></div>
                <div className='h-[53px]  w-[163px] my-[20px] sm:my-0'><img src={logo2}></img></div>
                <div className='h-[53px]  w-[163px] my-[20px] sm:my-0'><img src={logo3}></img></div>
                <div className='h-[53px]  w-[163px] my-[20px] sm:my-0'><img src={logo5}></img></div>
                <div className='h-[53px]  w-[163px] my-[20px] sm:my-0'><img src={logo6}></img></div>
            </div>
        </div>
    )
}
export default ProBonoContainer;