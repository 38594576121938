import React, { useState } from 'react'
import Input from '../../components/common/Input'
import Button from '../../components/common/Button'
import IconCall from '../../assets/contact/IconCall';
import { useLocation } from "wouter";
import { postData } from '../../utils/form'
import ModalForm from '../../components/common/ModalForm';


function FormContainer({ items }) {
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [correo, setCorreo] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [telefono, setTelefono] = useState('');
    const [pais, setPais] = useState('');
    const [comentarios, setComentarios] = useState('');
    const [statusMsg, setStatusMsg] = useState('')

    const [isNombreInvalid, setNombreInvalid] = useState(false);
    const [isApellidoInvalid, setApellidoInvalid] = useState(false);
    const [isCorreoInvalid, setCorreoInvalid] = useState(false);
    const [isTelefonoInvalid, setTelefonoInvalid] = useState(false);
    const [termns, setTermns] = useState(false)

    const [openModalForm, setOpenModalForm] = useState(false)


    const [location, setLocation] = useLocation();

    const disabledButton = nombre && apellido && correo && telefono && comentarios && termns ? true : false

    const navigate = (url) => {
        setLocation(url);
    };

    const handleSubmit = () => {
        if (!nombre || !apellido || !correo || !telefono) {
            setNombreInvalid(!nombre);
            setApellidoInvalid(!apellido);
            setCorreoInvalid(!correo);
            setTelefonoInvalid(!telefono)
            return;
        }
        const body = {
            name: `${nombre} ${apellido}`,
            email: correo,
            telephone: telefono,
            country: pais,
            company: empresa,
            comments: comentarios
        }
        postData(body)
        .then((response) => {
            if (response.status === 200) {
                setStatusMsg(response.data.msg);
                setNombre('');
                setApellido('');
                setCorreo('');
                setEmpresa('');
                setTelefono('');
                setPais('');
                setComentarios('');
                setTermns(false);
            } else {
                setStatusMsg(response.data.message);
            }
    
            setOpenModalForm(true);
        })
    };

    return (
        <div className='flex lg:flex-row flex-col'>
            <div className='flex lg:w-2/5'>
                <div className='lg:w-[20%] bg-[#D1C5B2]'></div>
                <div className='flex flex-col lg:w-5/6 w-full items-center bg-primary px-[80px] py-[120px]'>
                    <h2 style={{ fontFamily: 'Merriweather Italic' }} className='text-[#D1C5B2] lg:text-[30px] text-[26px]'>{items.subtitle}</h2>
                    <div className='text-[#fff] font-Poppins-Light pt-[30px]'>
                        <p className='text-[20px] pb-[20px]'>{items.p}</p>
                        {
                            items.address[0].items.map(p => <p className='text-[16px] pb-[10px]'>{p}</p>)
                        }
                        <div className='flex items-center text-[15px] pt-[25px]'>
                            <IconCall />
                            <p className='ml-[10px]'>{items.address[0].phone}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col lg:w-3/5 px-[60px] py-[90px]'>
                <p style={{ fontFamily: 'Poppins Light' }} className='text-primary'>{items.form.text}</p>
                <div className='flex max-w-[640px] justify-between flex-col lg:flex-row'>
                    <div className='lg:w-2/4 w-full'>
                        <div className='lg:w-[305px] w-full my-[20px]'>
                            <Input
                                type="text"
                                placeholder={items.form.name}
                                onChange={(e) => setNombre(e.target.value)}
                                value={nombre}
                                isInvalid={isNombreInvalid}
                            />
                        </div>
                        <div className='lg:w-[305px] w-full my-[20px]'>
                            <Input
                                type="email"
                                placeholder={items.form.email}
                                onChange={(e) => setCorreo(e.target.value)}
                                value={correo}
                                isInvalid={isCorreoInvalid}
                            />
                        </div>
                        <div className='lg:w-[305px] w-full my-[20px]'>
                            <Input
                                type="text"
                                placeholder={items.form.country}
                                value={pais}
                                onChange={(e) => setPais(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='lg:w-2/4 w-full'>
                        <div className='lg:w-[305px] w-full my-[20px]'>
                            <Input
                                type="text"
                                placeholder={items.form.lastname}
                                onChange={(e) => setApellido(e.target.value)}
                                value={apellido}
                                isInvalid={isApellidoInvalid}

                            />
                        </div>
                        <div className='lg:w-[305px] w-full my-[20px]'>
                            <Input
                                type="tel"
                                placeholder={items.form.phone}
                                onChange={(e) => setTelefono(e.target.value)}
                                value={telefono}
                                isInvalid={isTelefonoInvalid}
                            />
                        </div>
                        <div className='lg:w-[305px] w-full my-[20px]'>
                            <Input
                                type="text"
                                placeholder={items.form.company}
                                value={empresa}
                                onChange={(e) => setEmpresa(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <textarea
                    placeholder={items.form.comments}
                    value={comentarios}
                    onChange={(e) => setComentarios(e.target.value)}
                    className="max-w-[640px] sm:w-[820px] w-[280px] h-[140px] sm:my-[15px] sm:mr-0 mr-[15px] border border-primary rounded px-4 py-2 text-primary focus:outline-none focus:border-primary placeholder-primary::placeholder">
                </textarea>
                <div className="flex w-full sm:justify-start justify-center pt-[20px]">
                    <input onChange={() => setTermns(!termns)} checked={termns} className="mr-2 w-[22px] h-[22px]" type="checkbox" />
                    <label onClick={() => { navigate('/privacy-notice') }} className="md:w-2/3 flex underline cursor-pointer">
                        <span className="text-sm text-[#707070]">
                            {items.form.terms}
                        </span>
                    </label>
                </div>
                <div className='pt-[25px]'>
                    <Button disabled={!disabledButton} click={handleSubmit} backgroundColor='bg-secondary text-[16px]'>{items.form.button}</Button>
                    <p style={{ fontFamily: 'Poppins Medium' }} className='text-[16px] text-primary mt-[20px]'> {statusMsg} </p>
                </div>
            </div>
            {openModalForm && <ModalForm close={setOpenModalForm} />}
        </div>
    )
}

export default FormContainer;