import React, { useState, useContext } from 'react';
import Pagination from './Pagination';
import Card from '../../components/Recursos/Card';
import { LanguageContext } from '../../context/LanguageContext'


const PaginatedList = ({ articles, itemsPerPage, content }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { language } = useContext(LanguageContext);


  const handlePageChange = (direction) => {
    if (direction === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (direction === 'next' && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const items = [...articles]
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = items.slice(startIndex, endIndex);
  const totalPages = Math.ceil(items.length / itemsPerPage);

  return (
    <div className="paginated-list w-full">
        <div className='w-full flex justify-between items-baseline'>
            <h1 style={{fontFamily: 'Merriweather Bold'}} className='text-[#666666] sm:text-[40px] text-[26px]'>{content.title}</h1>
            <div className={`${content.items.length >= 4 ? 'block' : 'hidden'}`}>
                <Pagination
                totalItems={items.length}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                />
            </div>
        </div>
        <div className={`grid sm:grid-cols-[repeat(auto-fit,362px)] grid-cols-[repeat(auto-fit,332px)] pt-[45px] ${currentItems.length < 3 ? 'justify-start sm:gap-[90px] gap-y-[90px]'  : 'justify-between' }`}>
        {currentItems.map((item) => (
            <Card card={item} />
        ))}
      </div>
    
    </div>
  );
};

export default PaginatedList;
