const es = {
    "title": "Más de Cincuenta años de experiencia a tu favor",
    "subtitle": "Brindamos asesoría especializada en las Áreas de Práctica vinculadas con los negocios.",
    "p": "La experiencia de <span style='font-family: Poppins Medium'> Creel/abogados </span> se refleja en nuestras diversas Áreas de Práctica. Descubre más sobre nuestra experiencia en cada especialidad.",
    "items": [
        {
            "name": "Derecho Corporativo y Mercantil",
            "image": require('../../assets/despacho/experiencia/ND-DCorporativo_escritorio.jpg'),
            "link": "/practice-areas#corporate-and-commercial-law"
        },
        {
            "name": "Derecho Financiero",
            "image": require('../../assets/despacho/experiencia/ND-DFinanciero_escritorio.jpg'),
            "link": "/practice-areas#finance"
        },
        {
            "name": "Derecho Fiscal",
            "image": require('../../assets/despacho/experiencia/ND-DFiscal_escritorio.jpg'),
            "link": "/practice-areas#tax"
        },
        {
            "name": "Derecho Laboral",
            "image": require('../../assets/despacho/experiencia/ND-DLaboral_escritorio.jpg'),
            "link": "/practice-areas#labor-and-employment"
        },
        {
            "name": "Derecho Inmobiliario",
            "image": require('../../assets/despacho/experiencia/ND-DInmobiliario_escritorio.jpg'),
            "link": "/practice-areas#real-estate"
        },
        {
            "name": "Competencia Económica",
            "image": require('../../assets/despacho/experiencia/ND-Competencia_escritorio.jpg'),
            "link": "/practice-areas#antitrust-and-competition"
        },
        {
            "name": "Recursos Naturales",
            "image": require('../../assets/despacho/experiencia/ND-Recursos_escritorio.jpg'),
            "link": "/practice-areas#natural-resources"
        },
        {
            "name": "Litigio y Solución de Controversias",
            "image": require('../../assets/despacho/experiencia/ND-Litigio_escritorio.jpg'),
            "link": "/practice-area#litigation-and-dispute-resolution"
        },
        {
            "name": "ASG",
            "image": require('../../assets/despacho/experiencia/ND-ASG_escritorio.jpg'),
            "link": "/practice-areas#esg"
        },
        {
            "name": "Cumplimiento Regulatorio (Compliance)",
            "image": require('../../assets/despacho/experiencia/ND-Cumplimiento_escritorio.jpg'),
            "link": "/practice-areas#compliance"
        }
    ]
}

export default es;