import React from 'react'
import PaginatedList from '../../components/Recursos/PaginatedList';
function ArticlesContainer({items}){
    return (
        <div className='w-screen flex flex-col bg-[#fff] items-center'>
            <div className='w-full max-w-[1460px] 2xl:px-0 lg:p-[90px] p-[20px] pb-0'>
                    <PaginatedList articles={items?.items} itemsPerPage={3} content={items} />
            </div>
        </div>
    )
}

export default ArticlesContainer;