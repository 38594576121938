import React, { useState, useContext, useEffect } from 'react';
import { LanguageContext } from '../../context/LanguageContext'
import { useRoute, useLocation } from 'wouter';

import { getArticle } from '../../utils/articles';


import MainLayout from '../../Layout/MainLayout'
import ArticlesContainer from './ArticlesContainer';

import bg from '../../assets/recursos/HeaderArt.png'

function Detail() {
    const [match, params] = useRoute("/articles/:slug");
    const { language } = useContext(LanguageContext);
    const [location, state] = useLocation();

    const [articles, setArticles] = useState([])
    const [relacionado, setRelacionado] = useState([])


    useEffect(() => {
        window.scrollTo(0, 0);
        getArticle(params.slug)
            .then((res) => {
                setArticles(res.articulo)
                setRelacionado(res.relacionados)
            }
            )
            .catch(error => { console.log(error) })
    }, []);

    const pdfTrigger = (url) => {
        window.open(url, '_blank')
    }
    const articlesEsp = {title: language == 'es' ? "Artículos Relacionados": 'Related Capabilities', more: "Ver todo", items: relacionado }
    
    return (
        <MainLayout>
            <div style={{ backgroundImage: `url(${bg})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }} className='w-screen flex justify-center items-center bg-[#E2E2E2] h-[360px]'>
                <div className='w-[100%] flex flex-col justify-center items-center bg-[#000000] bg-opacity-[.69] h-[100%]'>
                    <div>
                        <h1 style={{ fontFamily: 'Merriweather Italic' }} className='text-[#D1C5B2] lg:text-[30px] text-[26px]'> {articles.nombre} </h1>
                    </div>
                </div>
            </div>
            <div className='p-[60px] flex flex-col justify-center items-center'>
                <div className='bg-[#F8F8F8] p-[20px] flex flex-col justify-center items-center max-w-[1400px]'>
                    <p className='text-clip'>
                        {articles.abstract}
                    </p>
                    <div className='flex justify-center items-center pt-[40px] pb-[40px]'>
                        <p onClick={() => pdfTrigger(articles.url_archivo)} style={{ fontFamily: 'Poppins Medium' }} className='bg-[#714061] text-[#fff] w-[170px] h-[50px] flex justify-center items-center cursor-pointer rounded-lg'>{language == 'es' ? 'LEER MÁS' : 'LEARN MORE'}</p>
                    </div>
                </div>
                <div>
                    <ArticlesContainer items={articlesEsp}/>
                </div>
            </div>
        </MainLayout>
    )
}
export default Detail