import React, { useState } from 'react';
import LogoFooter from '../../assets/common/LogoFooter';
import { postNewsLetter } from '../../utils/newsletter';
import ModalForm from '../../components/common/ModalForm';

const Footer = ({ items }) => {
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');

  const [openModalForm, setOpenModalForm] = useState(false);

  const postEmail = () => {
    postNewsLetter(email)
      .then(response => {
        if (response.res) { 
          setMsg(response.msg);
          setOpenModalForm(true);
        }
        else {
          setMsg(response.errors.email[0])
        }
      })
      .catch(error => {
        console.error(error);
      });
  }




  return (
    <footer className="bg-[#000] text-[#fff] min-h-[324px]">
      <div style={{ fontFamily: 'Poppins Light' }} className="container mx-auto py-8 px-4 md:px-8 text-[#fff] text-[11px]">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-[240px_1fr_1fr_1fr_1fr_290px] gap-4">
          <div className="footer-column flex flex-col sm:block items-center">
            <div className="sm:mb-[20px] mb-[40px] sm:h-[37px] h-[50px]">
              <LogoFooter className='sm:w-[114px] w-[200px]' />
            </div>
            {
              items.direccion.cdmx.map(p => <p className="sm:text-xs text-[16px] sm:my-[10px] my-0">{p}</p>)
            }
            <div className='pt-[15px] sm:block flex flex-col items-center'>
              {
                items.direccion.merida.map(p => <p className="sm:text-xs text-[16px] sm:my-[10px] my-0">{p}</p>)
              }
            </div>
          </div>
          <div className="footer-column sm:block hidden">
            <h3 style={{ fontFamily: 'Poppins Bold' }} className="sm:text-xs text-[16px] font-bold mb-[20px] h-[37px] flex items-end">{items.inicio.title}</h3>
            <a href={`/#${items.inicio.p1.link}`} className="sm:text-xs text-[16px] my-[10px] block">{items.inicio.p1.name}</a>
            <a href={`/#${items.inicio.p2.link}`} className="sm:text-xs text-[16px] my-[10px] block">{items.inicio.p2.name}</a>
            <a href={`/#${items.inicio.p3.link}`} className="sm:text-xs text-[16px] my-[10px] block">{items.inicio.p3.name}</a>
          </div>
          <div className="footer-column sm:block hidden">
            <h3 style={{ fontFamily: 'Poppins Bold' }} className="sm:text-xs text-[16px] font-bold mb-[20px] h-[37px] flex items-end">{items.despacho[0].name}</h3>
            {
              items.despacho.map(p => <a href={`/our-firm#${p.link}`} className="sm:text-xs text-[16px] my-[10px] block">{p.name}</a>)
            }
          </div>
          <div className="footer-column sm:block hidden">
            <h3 style={{ fontFamily: 'Poppins Bold' }} className="sm:text-xs text-[16px] font-bold mb-[20px] h-[37px] flex items-end">{items.areas.title}</h3>
            <a href={`/practice-areas#${items.areas.p1.link}`} className="sm:text-xs text-[16px] my-[10px] block">{items.areas.p1.name}</a>
            <a href={`/practice-areas#${items.areas.p2.link}`} className="sm:text-xs text-[16px] my-[10px] block">{items.areas.p2.name}</a>
            <a href={`/practice-areas#${items.areas.p3.link}`} className="sm:text-xs text-[16px] my-[10px] block">{items.areas.p3.name}</a>
            <a href={`/practice-areas#${items.areas.p4.link}`} className="sm:text-xs text-[16px] my-[10px] block">{items.areas.p4.name}</a>
            <a href={`/practice-areas#${items.areas.p5.link}`} className="sm:text-xs text-[16px] my-[10px] block">{items.areas.p5.name}</a>
          </div>
          <div className="footer-column sm:block hidden">
            <h3 style={{ fontFamily: 'Poppins Bold' }} className="sm:text-xs text-[16px] font-bold mb-[20px] h-[37px] flex items-end"></h3>
            <a href={`/practice-areas#${items.competencias.p1.link}`} className="sm:text-xs text-[16px] my-[10px] block">{items.competencias.p1.name}</a>
            <a href={`/practice-areas#${items.competencias.p2.link}`} className="sm:text-xs text-[16px] my-[10px] block">{items.competencias.p2.name}</a>
            <a href={`/practice-areas#${items.competencias.p3.link}`} className="sm:text-xs text-[16px] my-[10px] block">{items.competencias.p3.name}</a>
            <a href={`/practice-areas#${items.competencias.p4.link}`} className="sm:text-xs text-[16px] my-[10px] block">{items.competencias.p4.name}</a>
            <a href={`/practice-areas#${items.competencias.p5.link}`} className="sm:text-xs text-[16px] my-[10px] block">{items.competencias.p5.name}</a>
          </div>
          <div className='footer-column sm:hidden flex px-[30px] justify-between text-[14px]'>
            <div>
              <a href={`/`} className='py-[10px] block'>{items.responsive.p1}</a>
              <a href={`/our-firm`} className='block py-[10px]'>{items.responsive.p2}</a>
              <a href={`/practice-areas`} className='block py-[10px]'>{items.responsive.p3}</a>
              <a href={`/our-team`} className='block py-[10px]'>{items.responsive.p4}</a>
            </div>
            <div>
              <a href={`/our-history`} className='block py-[10px]'>{items.responsive.p5}</a>
              <a href={`/resources`} className='block py-[10px]'>{items.responsive.p6}</a>
              <a href={`/contact`} className='block py-[10px]'>{items.responsive.p7}</a>
            </div>
          </div>
          <div className="footer-column flex flex-col sm:block items-center">
            <h3 style={{ fontFamily: 'Poppins Bold' }} className="sm:text-xs text-[16px] font-bold mb-[20px] h-[37px] flex items-end sm:block hidden"></h3>
            <p className="sm:text-xs text-[16px] my-[10px] sm:pl-0 pl-[50px] sm:pb-0 pb-[20px]">{items.subscribe.p1}</p>
            <div className="flex items-center h-[49px]">
              <input
                type="email"
                className="h-full w-auto px-4 py-2 rounded-l border border-solid border-gray-300 bg-[#000] text-[#fff] focus:outline-none focus:border-black"
                placeholder={items.subscribe.input}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button onClick={() => postEmail()} style={{ fontFamily: 'Poppins' }} className="w-[110px] h-full sm:w-auto px-4 py-2 rounded-r bg-[#fff] text-[#000]">
                {items.subscribe.button}
              </button>
            </div>
            <p id='Message' style={{ fontFamily: 'Poppins' }} className='mt-[10px] text-[#fff]'>{msg}</p>
          </div>
        </div>
        <div className='w-full flex justify-center mt-[30px]'>
          <a href="/privacy-notice" className='text-[18px] text-center sm:text-[14px]'>{items.notice}</a>
        </div>
      </div>
      {openModalForm && <ModalForm close={setOpenModalForm} />}
    </footer>
  );
};

export default Footer;
