import React from 'react';
import { useLocation } from "wouter";

import ArrowDown from '../../assets/common/ArrowDown'

const Accordion = ({ title, content, index, currentIndex, toggleAccordion, anchor }) => {
  const [location, setLocation] = useLocation();
  return (
    <div id={anchor} className={`border-b border-[#D1C5B2] flex`}>
      <div
        className={`flex justify-left p-4 cursor-pointer ${index === currentIndex ? 'w-full flex-col items-center' : 'items-center' }`}
        onClick={() => toggleAccordion(index)}
      > 
      
      <div className={`flex ${index === currentIndex ? 'bg-secondary h-[104px] w-full items-center pl-[60px]' : ''}`}>
        <div className={`p-[10px] w-[41px] h-[41px] mr-[35px] rounded-full transform transition-transform ${
                    index === currentIndex ? 'rotate-180 bg-[#D1C5B2]' : 'rotate-0 bg-primary'
                }`}>
                <ArrowDown width='22' fill={`${index === currentIndex ? '#6F2A43' : '#D1C5B2'}`} />
            </div>
            <h3 style={{fontFamily: 'Poppins'}} className={`${index === currentIndex ? 'text-[#fff]' : 'text-[#131313]'} md:text-[25px] text-[18px]`}>{title}</h3>
      </div>
      {index === currentIndex && (
        <div className={`p-4 ${index === currentIndex ? 'w-full mt-[15px] max-w-[1192px]' : '' }`}>
                <div className={`${index === currentIndex ? 'float-left' : 'hidden'}`}>
        {
            content.banner.map(banner => <div className='pr-[35px] pb-[32px]'> <img style={{width: content.measures.width, height: content.measures.height}} className='object-cover' src={banner}></img> </div> )
        }
      </div>
            {
                content.p.map(acc => <div>
                    <h3 dangerouslySetInnerHTML={{ __html: acc.title }}/>
                    {
                        acc.items.map(item =>                     
                        <p dangerouslySetInnerHTML={{ __html: item }} style={{fontFamily: 'Poppins Light'}} className="text-[#666666] leading-loose"/>
                        )
                    }
                    </div>)
            }
            {
                <div>
                <h3 style={{fontFamily: 'Poppins Light'}} className={`text-secondary pr-[60px] text-[20px] mt-[35px] mb-[40px]`}>{content.images.title}</h3>
                    <div style={{gridTemplateColumns: 'repeat(auto-fill, minmax(134px, 1fr))'}} className='grid gap-0 gap-y-8'>
                        {
                            content.images.items.map(image => 
                                <div onClick={() => setLocation(image.link)} className='flex flex-col items-start cursor-pointer'> 
                                    <img src={image.image} className='w-[134px]'></img>
                                    <p className='text-[9px] text-[#131313] text-center w-[134px] mt-[10px]'>{image.name}</p>
                                </div> )
                        }
                    </div>
                </div>
            }
        </div>
      )}
      </div>
    </div>
  );
};

export default Accordion;
