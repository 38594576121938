
import React, { useContext } from 'react'
import { LanguageContext } from '../../context/LanguageContext'



const ModalForm = ({close}) => {

    const { language } = useContext(LanguageContext);

    return (
        <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="fixed inset-0 bg-[#808080] bg-opacity-75 transition-opacity" aria-hidden="true"></div>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div class="relative transform overflow-hidden rounded-lg bg-[#fff] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <div class="sm:flex sm:items-start">
                                <div class="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="green">
                                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1.29 14.71l-4-4a1 1 0 1 1 1.41-1.41l2.3 2.29 5.29-5.29a1 1 0 1 1 1.41 1.41l-6 6a1 1 0 0 1-1.41 0z" />
                                    </svg>

                                </div>
                                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <h3 class="text-base font-semibold text-[#000]" id="modal-title">{language == 'es' ? '¡Formulario enviado!' : 'Form submitted!'}</h3>
                                    <div class="mt-2">
                                        <p class="text-sm text-[#000]">{language == 'es' ? 'Registro realizado correctamente' : 'Registration completed successfully'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button onClick={() => close(false)} type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-[#000] shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">{language == 'es' ? 'Cerrar' : 'Close'}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ModalForm;