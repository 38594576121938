import React from 'react'
import { useLocation } from "wouter";

import ArrowLeft from '../../assets/common/ArrowLeft'

function ShowMore({ item, primary, pasantes, setIsModalOpen }) {
    const [location, setLocation] = useLocation();
    const navigate = (url) => {
        setLocation(url);
    };
    return (
        <div onClick={item.link ? () => navigate(item.link) : () => setIsModalOpen(item)} className={`cursor-pointer group flex flex-col justify-end ${primary ? 'sm:w-[265px] sm:h-[265px] w-[163px] h-[163px]' : 'w-[146px] h-[146px] sm:w-[235px] sm:h-[230px]'}`}>
            <div style={{ backgroundImage: `url(${item.image})`, backgroundSize: 'cover', backgroundPosition: 'top' }} className={`w-full ${item.name === 'Home' || pasantes ? 'h-auto' : 'h-full'}`}>
                {/* <img  className='' src={item.image ? image() || item.image  : '' } ></img> */}
            </div>
            <div className={`${pasantes ? 'absolute flex justify-center items-center h-[146px] w-[146px] sm:h-[235px] sm:w-[235px] group-hover:h-[235px] group-hover:bg-[#6F2A43] sm:bg-[#D1C5B2] bg-primary' : 'group-hover:absolute group-hover:flex group-hover:justify-center group-hover:items-center'} ${item.name === 'Home' ? 'h-[146px] w-[146px] sm:h-[235px] sm:w-[235px] sm:inline-grid hidden' : ''} ${primary && !pasantes ? 'group-hover:h-[265px] group-hover:w-[265px] bg-primary sm:h-[80px] h-[48px] flex justify-center' : 'group-hover:h-[235px] group-hover:w-[235px] group-hover:bg-[#D1C5B2] sm:bg-[#E4E4E4] bg-primary'}`}>
                <div style={{ fontFamily: 'Poppins Medium' }} className={`${pasantes ? 'border-primary group-hover:border-[#AA9775] border-solid border-2 flex justify-center items-center w-[190px] h-[146px] sm:h-[190px] sm:p-[10px] p-[5px]' : 'sm:h-full'} group-hover:transition-all group-hover:border-solid group-hover:border-2 flex justify-center items-center text-[18px] ${primary ? 'group-hover:w-[219px] group-hover:h-[219px] group-hover:border-[#fff] sm:p-[40px] p-0' : 'group-hover:w-[190px] group-hover:h-[190px] border-primary sm:p-[10px] p-[5px] h-[56px]'}`}>
                    <div className={`${pasantes ? 'flex flex-col justify-center items-center' : ' flex flex-col'}`}>
                    <p className={`text-center ${pasantes ? 'group-hover:text-[#fff]' : ''} ${primary && !pasantes ? 'text-[#fff] text-[10px] sm:text-[16px]' : 'sm:text-primary text-[#fff] text-[14px] sm:font-Poppins-Medium font-Poppins-Light'}`}>
                            {item.legend}
                        </p>
                        <p className={`text-center ${pasantes ? 'group-hover:text-[#fff]' : ''} ${primary && !pasantes ? 'text-[#fff] text-[10px] sm:text-[16px]' : 'sm:text-primary text-[#fff] text-[14px] sm:font-Poppins-Medium font-Poppins-Light'}`}>
                            {item.name === 'Home' && <span className='sm:inline-block w-[10px] mr-[5px] hidden'>
                                <ArrowLeft fill="#5D2648" strokeWidth="10px" />
                            </span>} {item.name}
                        </p>
                        {item.description &&
                            <p style={{ fontFamily: 'Poppins Light' }} className={`${primary ? 'text-[#fff]' : 'text-[#D1C5B2] text-[12px] group-hover:flex hidden pt-[10px] underline'}`}>{item.description}</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShowMore;