const en = {
    "hero.slider":[
     {
         "title": "Ensuring legal certainty for your business",
         "button": "Meet our Team",
         "button_hover": "Meet our Team",
         "link": "/our-team"
     },
     {
         "title": "To prevent is to solve future problems",
         "button": "Discover our Areas of Practice",
         "button_hover": "Discover our Areas of Practice",
         "link": "practice-areas"
     }
 ],
 "negocios": {
    "text": {
     "title": "Experts in Business Law",
     "subtitle": "We understand the challenges of your organization and deliver effective execution with full focus on results.",
     "p1": "We devise and implement high-impact solutions that safeguard and enhance your structural and strategic interests.",
     "p2": "Proximity, personalized attention and commitment to excellence are the structural pillars of our Firm; always being at the forefront of the ever-changing realities of the 21st century."
    },
    "showMore": [
     {
         "text": "Professional Purpose",
         "more": "Read more",
         "description": "Aligning the strategic objectives of our clients with those of our Firm. We measure our success by the achievements of our clients.",
         "image": require('../../assets/home/showMore/H-PropositoP_escritorio.jpg'),
         "image_movil": require('../../assets/home/showMore/H-PropositoP_movil.jpg'),
         "link": "our-firm"
     },
     {
         "text": "Working Teams",
         "more": "Read more",
         "description": "With our collaborative culture, we assemble well-trained and experienced working teams to serve the specific needs of each client, matter or project, and provide legal certainty and instill confidence with our services.",
         "image": require('../../assets/home/showMore/H-EquiposT_escritorio.jpg'),
         "image_movil": require('../../assets/home/showMore/H-EquiposT_movil.jpg'),
         "link": "/our-team"

     },
     {
         "text": "Practice Areas",
         "more": "Read more",
         "description": "In each of our Practice Areas we offer in-depth comprehensive expertise and experience ensuring consistency in our services and effective results.",
         "image": require('../../assets/home/showMore/H-AreasP_escritorio.jpg'),
         "image_movil": require('../../assets/home/showMore/H-AreasP_movil.jpg'),
         "link": "/practice-areas"
     }
 ]
 },
 "socios": {
    "text": {
        "title": "Always by your side",
        "subtitle": "The Creel/abogados Team provides the required legal knowledge and support enabling you to focus on the path to your company's success.",
        "p1": "Our partners are personally involved in all matters and projects entrusted to us, ensuring timely and effective execution of mutually agreed objectives.",
        "p2": "We take pride in our Team of talented lawyers, who possess solid experience and expertise in the various legal fields that constitute our practice."
    },
    "slider": [
        {
            "name": "Gustavo Struck Creel",
            "title": "Corporate and Commercial Law",
            "button": "Contact",
            "image": require('../../assets/home/slider/abogadoSlider1.png'),
            "image_2": require('../../assets/home/slider/abogadoSlider1_2.png'),
            "link": "/partners/3"
        },
        {
            "name": "Sandra López Ch.",
            "title": "Corporate and Commercial Law",
            "button": "Contact",
            "image": require('../../assets/home/slider/abogadoSlider2_A.png'),
            "image_2": require('../../assets/home/slider/abogadoSlider2.png'),
            "link": "/associates/6"
        }
    ]
},
"meet": {
    "text":{
        "title": "Know our Team",
        "button": "Know our Team"
    },
    "tabs": [
        {
            "key": "team", 
            "value": "Team"
        },
    ]
},
"form": {
    "text": {
        "title": "Being in contact with you and understanding your legal advice needs is our priority.",
        "subtitle": "We are here to listen.",
        "description": "Contact Form",
        "fields": "Required fields",
        "termns": "I accept the terms of the Privacy Notice",
        "button": "Send form"
    },
    "fields": {
        "name": "Name",
        "lastname": "Last name",
        "email": "Email",
        "country": "Country",
        "comments": "Comments",
        "company": "Business",
        "phone": "Telephone"
    }
}
 }

export default en;